import {
  BrowserRouter,
  Navigate,
  Route,
  Routes
} from 'react-router-dom';
import { PublicRoutes } from './public-routes';

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<PublicRoutes />} />
        <Route path="*" element={<Navigate to="*" replace />} />
      </Routes>
    </BrowserRouter>
  );
}
