import { useState } from 'react';
import { Link } from 'react-scroll';
import { Tooltip } from 'react-tooltip';
// import 'react-tooltip/dist/index.css'; // Ensure to import default styles from react-tooltip

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleGetStarted = () => {
    window.location.href = '/login';
  };

  return (
    <nav className="sticky top-0 z-50 bg-white  flex items-center justify-between py-4 w-full">
      {/* Logo Container */}
      <div className="w-48 flex justify-center">
        <a href="/" className="text-3xl font-black text-black">
          Prayr
        </a>
      </div>
      {/* Navigation Links */}
      <div className="hidden md:flex space-x-4 flex-grow flex justify-center">
        <Link
          activeClass="font-bold"
          to="home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="px-3 py-2 rounded cursor-pointer"
        >
          Home
        </Link>
        <Link
          activeClass="font-bold"
          to="about"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="px-3 py-2 rounded cursor-pointer"
        >
          About
        </Link>
        <Link
          activeClass="font-bold"
          to="waitlist"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="px-3 py-2 rounded cursor-pointer"
        >
          Waitlist
        </Link>
      </div>
      {/* Navigation Links */}
      <div className="w-48 flex justify-center items-center space-x-4">
        <button
          disabled
          className="black-outlined-button hidden md:inline-block disabled:cursor-not-allowed disabled:opacity-50"
          data-tooltip-id="my-tooltip-pink"
          data-tooltip-content="✨ Be patient, it's on the way! ✨"
        >
          Get Started
        </button>
        <div className="example-container">
          <Tooltip id="my-tooltip-pink" className="example-pink" />
        </div>
        <button
          className="md:hidden px-3 py-2 rounded-md text-gray-700"
          onClick={toggleMobileMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>
      </div>
      <div
        className={`${
          isMobileMenuOpen ? 'flex' : 'hidden'
        } flex-col absolute top-full right-0 bg-white shadow-md py-2 space-y-2 w-full md:hidden px-4`}
      >
        <Link
          activeClass="font-bold"
          to="home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="px-3 py-2 rounded cursor-pointer text-center"
        >
          Home
        </Link>
        <Link
          activeClass="font-bold"
          to="about"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="px-3 py-2 rounded cursor-pointer text-center"
        >
          About
        </Link>
        <Link
          activeClass="font-bold"
          to="waitlist"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="px-3 py-2 rounded cursor-pointer text-center"
        >
          Waitlist
        </Link>
        <button
          disabled
          className="black-outlined-button hidden md:inline-block disabled:cursor-not-allowed disabled:opacity-50"
          data-tooltip-id="my-tooltip-pink"
          data-tooltip-content="✨ Be patient, it's on the way! ✨"
        >
          Get Started
        </button>
        <div className="example-container">
          <Tooltip id="my-tooltip-pink" className="example-pink" />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
