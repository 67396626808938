import {
  createAsyncThunk,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { resetStore, RootState } from '.';
import { AppState } from '../types';
import authService from '../services/auth.service';
import { getObjFromLocalStorage } from '../utils';
import { Cookies } from 'react-cookie';

const initialState = {
  accessToken:
    getObjFromLocalStorage('prayr-user-access-token') || null,
  refreshToken:
    getObjFromLocalStorage('prayr-user-refresh-token') || null,
  status: 'idle'
};

export const login = createAsyncThunk(
  'auth/login',
  async (
    credentials: { email: string; password: string },
    { rejectWithValue }
  ) => {
    const res = await authService.login(
      credentials.email,
      credentials.password
    );

    console.log(res);
    if (res?.error) {
      document.cookie = `auth-err=${res?.error}`;
      return rejectWithValue(res.error);
    }

    return {
      accessToken: res.accessToken,
      refreshToken: res.refreshToken
    };
  }
);

export const register = createAsyncThunk(
  'auth/register',
  async (
    credentials: {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
    },
    { rejectWithValue }
  ) => {
    const res = await authService.register(
      credentials.firstName,
      credentials.lastName,
      credentials.email,
      credentials.password
    );

    if (res?.error) {
      document.cookie = `auth-err=${res?.error}`;
      return rejectWithValue(res.error);
    }

    return {
      accessToken: res.accessToken,
      refreshToken: res.refreshToken
    };
  }
);

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    let refreshToken = state.authData?.refreshToken;

    if (refreshToken === undefined) {
      return rejectWithValue('Missing refresh token !!!');
    }

    const accessToken = await authService.refreshToken(refreshToken);

    return {
      accessToken: accessToken,
      refreshToken: refreshToken
    };
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthState(state, action) {
      localStorage.setItem(
        'prayr-user-access-token',
        JSON.stringify(action.payload?.accessToken)
      );
      localStorage.setItem(
        'prayr-user-refresh-token',
        JSON.stringify(action.payload?.refreshToken)
      );

      state.accessToken = action.payload?.accessToken;
      state.refreshToken = action.payload?.refreshToken;
    }
  },
  // use extra reducers when the action has to be handled by multiple reducers
  extraReducers: builder => {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(register.pending, state => {
        state.status = 'loading';
      })
      .addCase(login.pending, state => {
        state.status = 'loading';
      })
      .addCase(refreshToken.pending, state => {
        state.status = 'loading';
      })
      .addCase(
        register.fulfilled,
        (state, action: PayloadAction<AppState['auth']>) => {
          state.status = 'fulfilled';

          const { setAuthState } = authSlice.caseReducers;
          setAuthState(state, action);
        }
      )
      .addCase(
        login.fulfilled,
        (state, action: PayloadAction<AppState['auth']>) => {
          state.status = 'fulfilled';

          const { setAuthState } = authSlice.caseReducers;
          setAuthState(state, action);
        }
      )
      .addCase(
        refreshToken.fulfilled,
        (state, action: PayloadAction<AppState['auth']>) => {
          state.status = 'fulfilled';

          const { setAuthState } = authSlice.caseReducers;
          setAuthState(state, action);
        }
      )
      .addCase(register.rejected, state => {
        state.status = 'failed';
      })
      .addCase(login.rejected, state => {
        state.status = 'failed';
      })
      .addCase(refreshToken.rejected, state => {
        state.status = 'failed';
      });
  }
});

export const { setAuthState } = authSlice.actions;
