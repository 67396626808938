import axios, { HttpStatusCode } from 'axios';
import { resetStore, store } from '../store';
import { jwtDecode } from 'jwt-decode';
import { refreshToken } from '../store/auth.slice';

export const privateInstance = axios.create({
  baseURL: 'http://localhost:3001/api/v1'
});

export const instance = axios.create({
  baseURL: 'http://localhost:3001/api/v1'
});

let refreshAndRetryQueue: any = [];
let isRefreshing = false;

async function refreshTk() {
  isRefreshing = true;

  try {
    await store.dispatch(refreshToken());
    let newToken = store.getState()?.authData?.accessToken;

    refreshAndRetryQueue.forEach((resolve: any) =>
      resolve(newToken)
    );
  } catch (refreshError) {
    refreshAndRetryQueue.forEach((reject: any) =>
      reject(refreshError)
    );
    refreshAndRetryQueue = [];
  } finally {
    isRefreshing = false;
  }
}
privateInstance.interceptors.request.use(
  async (config: any) => {
    console.log('Making request to: ', config.url);

    const authData = store?.getState()?.authData;
    let currentDate = new Date();

    if (authData?.accessToken) {
      const decodedToken: { exp: number } = jwtDecode(
        authData?.accessToken
      );
      if (
        decodedToken.exp * 1000 < currentDate.getTime() &&
        config.url !== 'auth/refresh-token'
      ) {
        if (!isRefreshing) refreshTk();
        return new Promise(resolve => {
          refreshAndRetryQueue.push((newAccessToken: string) => {
            config.headers['authorization'] =
              `Bearer ${newAccessToken}`;

            resolve(config);
          });
        });
      } else
        config.headers['authorization'] =
          `Bearer ${authData?.accessToken}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

privateInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.status === HttpStatusCode.Unauthorized) {
      store.dispatch(resetStore());

      window.localStorage.removeItem('prayr-user-access-token');
      window.localStorage.removeItem('prayr-user-refresh-token');
      window.localStorage.removeItem('prayr-user-profile');
      window.location.href = '/';
    }

    return Promise.reject(error);
  }
);

export default instance;
