const Footer = () => {
  return (
    <footer className="text-center p-4 py-7 text-[#B6B6B6]">
      <p>
        &copy; 2024 Prayr by{' '}
        <a
          href="https://www.linkedin.com/in/hammadharquil/"
          target="_blank"
          rel="noreferrer"
          className="font-semibold text-[#404040]"
        >
          Hammadh Arquil
        </a>{' '}
        &{' '}
        <a
          href="https://www.linkedin.com/in/luqmanrumaiz/"
          target="_blank"
          rel="noreferrer"
          className="font-semibold text-[#404040]"
        >
          Luqman Rumaiz
        </a>
        .
        <br /> All Rights Reserved.
      </p>
    </footer>
  );
};
export default Footer;
