import AppRouter from './app-router';
import { useEffect } from 'react';
import { Cookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from '../store';
import { setAuthState } from '../store/auth.slice';
import { getProfile } from '../store/profile.slice';
import { getUserId } from '../utils';

function App() {
  const profileStatus = useAppSelector(
    state => state?.profile?.status
  );
  const accessToken = useAppSelector(
    state => state?.authData?.accessToken
  );
  const refreshToken = useAppSelector(
    state => state?.authData?.refreshToken
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    const cookies = new Cookies();

    const accessTokenCookie = cookies.get('access-token');
    const refreshTokenCookie = cookies.get('refresh-token');

    if (accessTokenCookie && refreshTokenCookie) {
      dispatch(
        setAuthState({
          accessToken: accessTokenCookie,
          refreshToken: refreshTokenCookie
        })
      );

      cookies.remove('access-token');
      cookies.remove('refresh-token');
    }
  }, [dispatch, accessToken, refreshToken]);

  useEffect(() => {
    if (getUserId() && profileStatus === 'idle') {
      dispatch(getProfile());
    }
  }, [dispatch, profileStatus]);

  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
