import request from 'axios';
import { instance as api } from './service';

const waitlistService = {
  joinWaitlist: async (email: string) => {
    try {
      const response = await api.post('/waitlist/new-subscriber', {
        email
      });
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error)) {
        return { error: error.response?.data?.error?.message };
      }
      throw error;
    }
  }
};

export default waitlistService;
