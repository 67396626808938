import { privateInstance as api } from './service';

const profileService = {
  getProfileData: async (userId: string) => {
    try {
      const response = await api.get(
        `profile/get-profile/${userId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

export default profileService;
