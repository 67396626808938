import { jwtDecode } from 'jwt-decode';
import { getObjFromLocalStorage } from './get-obj-from-storage.utils';

export const getUserId = () => {
  const token = getObjFromLocalStorage('prayr-user-access-token');
  if (!token) return null;

  const decodedToken: { id: string } = jwtDecode(token);
  return decodedToken?.id;
};
