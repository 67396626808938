import request from 'axios';
import { instance as api } from './service';

const authService = {
  register: async (
    firstName: string,
    lastName: string,
    email: string,
    password: string
  ) => {
    try {
      const response = await api.post('/auth/register', {
        firstName,
        lastName,
        email,
        password
      });
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error)) {
        return { error: error.response?.data?.error?.message };
      }
      throw error;
    }
  },
  login: async (email: string, password: string) => {
    try {
      const response = await api.post('/auth/login', {
        email,
        password
      });
      return response.data;
    } catch (error) {
      if (request.isAxiosError(error)) {
        return { error: error.response?.data?.error?.message };
      }
      throw error;
    }
  },
  refreshToken: async (refreshToken: string) => {
    try {
      const response = await api.post('/auth/refresh-token', {
        refreshToken: refreshToken
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

export default authService;
