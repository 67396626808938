import { configureStore, createAction } from '@reduxjs/toolkit';
import { authSlice } from './auth.slice';
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector
} from 'react-redux';
import { profileSlice } from './profile.slice';

export const resetStore = createAction('store/reset');

export const store = configureStore({
  reducer: {
    authData: authSlice.reducer,
    profile: profileSlice.reducer
  }
});

// This uses TypeScript's ReturnType utility type to infer the type based on the store's getState method.
export type RootState = ReturnType<typeof store.getState>;

// This uses TypeScript's typeof operator to get the type of the store's dispatch method so that errors caused by
// an incorrect action type or payload structure an error is clearly shown
export type AppDispatch = typeof store.dispatch;

// This custom hook is typed with AppDispatch, ensuring that when you use this hook to dispatch actions,
// you're using the correctly typed dispatch function according to your Redux store's setup.
export const useAppDispatch = () => useDispatch<AppDispatch>();

// This custom hook is typed with RootState, ensuring that when you use this hook to select data from the Redux store,
// you're working with the correctly typed state according to your Redux store's setup.
export const useAppSelector: TypedUseSelectorHook<RootState> =
  useSelector;
