import { useEffect } from 'react';
import WaitlistForm from '../../components/landing/waitlist.component';
import { Events, scrollSpy } from 'react-scroll';
import Footer from '../../components/landing/footer.component';
import Navbar from '../../components/landing/navbar.component';
import HeroSection from '../../components/landing/hero-section.component';
import FAQSection from '../../components/landing/faq.component';

const LandingPage = () => {
  useEffect(() => {
    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const faqs = [
    {
      question: 'What is Prayr?',
      answer:
        'Prayr is an Islamic app that helps you stay connected to your faith effortlessly. It provides features like prayer times, tracking Salah, reciting Quran, Duas & Hadith, Q&A, and Islamic articles.'
    },
    {
      question:
        'Why should I use Prayr if there are other similar solutions?',
      answer:
        'Thats a good question, we set forth our journey with the need to create a solution that provides the best user experiences and quality of life improvements compared to several other solutions and will continue with that focus in mind.'
    },
    {
      question: 'When will Prayr be launched?',
      answer:
        "We're working hard to get the Prayr app into your hands soon and will let you know as soon as it is ready!"
    },
    {
      question: 'How can I sponsor Prayr?',
      answer:
        'Interested in sponsoring our solution? Feel free to reach out to us on social media or through our email at contact.prayr@gmail.com'
    }
  ];

  return (
    <>
      <Navbar />
      <header className="bg-gradient-radial px-4 sm:px-6">
        {/* <hr className="border-t border-[#D4DFE7]" /> */}
        <HeroSection />
      </header>
      <hr className="border-t border-[#D4DFE7]" />
      <FAQSection faqs={faqs} />
      <WaitlistForm />
      <hr className="border-t border-[#D4DFE7] mt-2" />
      <Footer />
    </>
  );
};

export default LandingPage;
