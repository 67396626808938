import { resetStore } from './index';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import profileService from '../services/profile.service';
import { getUserId } from '../utils';

const getProfileData = () => {
  const profileData = localStorage.getItem('prayr-user-profile');
  return profileData ? JSON.parse(profileData) : {};
};

const initialState = {
  id: getUserId() || null,
  firstName: getProfileData().firstName || null,
  lastName: getProfileData().lastName || null,
  email: getProfileData().email || null,
  status: 'idle'
};

export const getProfile = createAsyncThunk(
  'profile/getProfile',
  async (_, { rejectWithValue }) => {
    const userId = getUserId();
    if (userId == null)
      return rejectWithValue('Missing user id !!!');

    const profileData = await profileService.getProfileData(userId);

    return profileData;
  }
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(getProfile.pending, state => {
        state.status = 'loading';
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.id = action.payload?.id;
        state.firstName = action.payload?.firstName;
        state.lastName = action.payload?.lastName;
        state.email = action.payload?.email;

        localStorage.setItem(
          'prayr-user-profile',
          JSON.stringify(action.payload)
        );
      })
      .addCase(getProfile.rejected, state => {
        state.status = 'failed';
      });
  }
});
