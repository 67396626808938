import { Navigate, Route, Routes } from 'react-router-dom';
import LandingPage from '../pages/landing/landing.page';

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
