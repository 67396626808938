import { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="item px-6 py-3">
      <div
        className="flex items-center justify-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h4 className={`text-xl ${isOpen ? 'font-semibold' : ''}`}>
          {question}
        </h4>
        <IoIosArrowDown
          className={`transform transition-transform ${
            isOpen ? 'rotate-180' : ''
          }`}
        />
      </div>
      {isOpen && (
        <div className="mt-3 text-gray-600">
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

const FAQSection = (props: { faqs: FAQItemProps[] }) => {
  return (
    <div className="mx-auto max-w-7xl my-7" id="about">
      <div className="p-2 rounded">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/3 p-4 text-sm">
            <h2 className="text-3xl font-semibold">
              Frequently Asked Questions
            </h2>
            <p className="mt-3 text-xl text-[#B6B6B6]">
              Feel free to ask any question if you would like to
              learn more about this amazing product. Don't hesitate.
            </p>
            <div className="mt-5">
              <a
                href="https://twitter.com/ThePrayrApp"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[#313131] text-base text-medium hover:text-[#65BC0F] transition-transform transform hover:scale-105"
              >
                Visit Prayr on Twitter
              </a>
              <span className="mx-2">•</span>
              <a
                href="mailto:contact.prayr@gmail.com"
                className="text-[#313131] text-base text-medium hover:text-[#65BC0F] transition-transform transform hover:scale-105"
              >
                Email us!
              </a>
            </div>
          </div>

          <div className="md:w-2/3">
            {props.faqs.map((faq, index) => (
              <>
                <FAQItem
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                />
                {index !== props.faqs.length - 1 && (
                  <hr className="border-t border-gray-300 ml-5 mr-5 mt-2 mb-2" />
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
