import BlackOutlineButton from '../common/button/black-outlined.component';
import { useFormik } from 'formik';
import { waitlistSchema } from '../../validation/waitlist.validation';
import { waitlistService } from '../../services';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function JoinWaitlistForm() {
  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const formik = useFormik({
    initialValues: {
      user_email: ''
    },
    validationSchema: waitlistSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { resetForm }) => {
      const response = await waitlistService.joinWaitlist(
        values.user_email
      );
      if (response.error) {
        toast.error(
          'An error occurred while trying to subscribe to the Waitlist!'
        );
      } else {
        toast.success(
          'You have successfully subscribed to the Waitlist!!'
        );

          emailjs.sendForm(
            process.env.REACT_APP_SERVICE_ID as string,
            process.env.REACT_APP_TEMPLATE_ID as string,
            formRef.current as HTMLFormElement,
            process.env.REACT_APP_PUBLIC_KEY
          );
      }
      resetForm();
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  });

  return (
    <>
      <hr className="border-t border-gray-300 mt-5" />
      <section className="text-center p-6 my-10" id="waitlist">
        <h2 className="text-2xl font-bold mb-3 sm:text-5xl">
          Join the waitlist today!
        </h2>
        <p className="sm:text-xl mb-5 text-[#B6B6B6]">
          Get notified when there are updates on the product!
        </p>
        <form
          className="mt-4 flex-col sm:flex-row sm:items-center gap-2 md:mx-20 lg:mx-40 xl:mx-60 2xl:mx-96"
          onSubmit={formik.handleSubmit}
          ref={formRef}
        >
          <input
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.user_email}
            type="email"
            id="Email"
            name="user_email"
            className={`max-w-[90%] sm:max-w-[60%] sm:mr-2 px-4 py-2 border-2 border-[#D5D5D5] rounded-full focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50 ${
              formik.errors.user_email && formik.touched.user_email
                ? 'input-error'
                : ''
            }`}
            placeholder="Enter your email address*"
          />
          <button
            className="mt-4 sm:mt-0  sm:w-auto black-background-button"
            type="submit"
          >
            Join Now
          </button>
        </form>
        {formik.touched.user_email && formik.errors.user_email && (
          <p className="mt-2 text-sm text-red-600 dark:text-red-500 w-full">
            {formik.errors.user_email}
          </p>
        )}
      </section>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />{' '}
    </>
  );
}
