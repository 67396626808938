import LandingHomeImageSrc from '../../assets/images/landing-home.png';

const HeroSection = () => {
  return (
    <>
      <div
        id="home"
        className="text-center px-4 sm:px-6 lg:px-8 py-12"
      >
        <h1 className="text-4xl fade-up-25 sm:text-5xl md:text-6xl lg:text-8xl font-bold mb-5">
          Stay connected to your
          <br /> faith effortlessly.
        </h1>
        <div className="mt-8 fade-up-25 leading-relaxed font-medium text-sm sm:text-base md:text-lg lg:text-xl mx-auto flex justify-center items-center flex-wrap gap-2">
          <div className="bg-[#E8F5DB] px-5 py-1 rounded-full flex items-center">
            <div className="w-3 h-3 bg-[#65BC0F] mr-2 rounded-full"></div>
            <p>Prayer Times</p>
          </div>
          <div className="bg-[#DBEDF5] px-5 py-1 rounded-full flex items-center">
            <div className="w-3 h-3 bg-[#0F88BC] mr-2 rounded-full"></div>
            <p>Track Salah</p>
          </div>
          <div className="bg-[#DBE2F5] px-5 py-1 rounded-full flex items-center">
            <div className="w-3 h-3 bg-[#0F3FBC] mr-2 rounded-full"></div>
            <p>Q & A</p>
          </div>
          <div className="bg-[#F5DBE8] px-5 py-1 rounded-full flex items-center">
            <div className="w-3 h-3 bg-[#BC0F62] mr-2 rounded-full"></div>
            <p>Recite Quran</p>
          </div>
          <div className="bg-[#E7DBF5] px-5 py-1 rounded-full flex items-center">
            <div className="w-3 h-3 bg-[#5B0FBC] mr-2 rounded-full"></div>
            <p>Duas & Hadith</p>
          </div>
          <div className="bg-[#FBEDD9] px-5 py-1 rounded-full flex items-center">
            <div className="w-3 h-3 bg-[#E18700] mr-2 rounded-full"></div>
            <p>Islamic Articles</p>
          </div>
        </div>
      </div>
      <div className="text-center">
        <span className="text-xs sm:text-xl fade-up-25 text-gray-400 font-light px-2">
          Launching soon - Join the waitlist
        </span>
      </div>
      <div className="flex justify-center items-center">
        <img
          src={LandingHomeImageSrc}
          alt="Dynamic Content"
          className="w-[80%] rounded-xl"
        />
      </div>
    </>
  );
};

export default HeroSection;
